import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoginComponent } from './Auth/login/login.component'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CdkColumnDef } from '@angular/cdk/table';
import { IonicStorageModule } from '@ionic/storage';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { JWTInterceptor } from './helpers/jwtinterceptor';
import localeEs from '@angular/common/locales/es';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { SafePipe } from './folder/pipe/urlPipe';
registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [AppComponent, LoginComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    PdfViewerModule,
    MatTableModule,
    CdkTableModule,
    RouterModule,
    IonicStorageModule.forRoot(),
    MatButtonModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    [CdkColumnDef]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
