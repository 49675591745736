import { Component, OnInit } from '@angular/core';
import { AlertController } from "@ionic/angular";
import { AuthGuardService } from "../auth-guard.service"
import { Router } from "@angular/router";
import { MenuController } from '@ionic/angular';
import { RouterModule } from '@angular/router';
//import { PagesService } from "./../../api/pages.service";
import { AppComponent } from "../../app.component";

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss'],
})
export class ResetPassComponent implements OnInit {

  constructor(public alertController: AlertController,
 private menu: MenuController, public authService: AuthGuardService, private router: Router,) {
    this.menu.enable(false, 'custom');
  }

  ngOnInit() { }

  confirmarPass() {
    let passCambiar = {};

    passCambiar["correo"] = (<HTMLInputElement>document.getElementById("correo")).value
    this.authService.cambiarPass(passCambiar).subscribe((data) => {
      console.log(data['success'])
      if (data['success'] == "Editado") {
        this.passGuardada();
        this.router.navigate(["login"]);
      }
    });

  }
  async passGuardada() {
    let alert = await this.alertController.create({
      header: "Contraseña Actualizada",
      message: "La contraseña fue modificada exitosamente",
    });
    await alert.present();
  }

  async notValidPassAlert() {
    let alert = await this.alertController.create({
      header: "Contraseña no valida",
      message:
        "La contraseña debe ser de al menos 8 carácteres debe incluir al menos un digito, una mayúscula y un carácter especial (@$%^&*-)",
    });
    await alert.present();
  }
}
