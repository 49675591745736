import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular'; //import MenuController to access toggle() method.
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public MenuCarga;
  public appPages = [{
      title: 'Cuotas',
      url: '/folder/Cuotas',
      icon: 'book'
    },{
      title:'Cuotas Adaptadas',
      url: '/folder/CuotasAdaptadas',
      icon:'folder'
    },{
      title: 'Capturas',
      url: '/folder/Capturas',
      icon: 'fish'
    },{
      title: 'Consulta',
      url: '/folder/Consulta',
      icon: 'reader'
    },{
      title:'Informes',
      url: '/folder/Informe',
      icon:'documents'
    },{
      title: 'Usuario',
      url: '/folder/Usuario',
      icon: 'person'
    },{
      title:'Grupo Empresarial',
      url: '/folder/GrupoEmpresarial',
      icon:'people'
    },{
      title: 'Buques',
      url: '/folder/Buques',
      icon: 'boat'
    }
  ];

  public BuquePage = [{
      title:'Cuotas Adaptadas',
      url: '/folder/CuotasAdaptadas',
      icon:'folder'
    },{
      title: 'Consulta',
      url: '/folder/Consulta',
      icon: 'reader'
    },{
      title:'Informes',
      url: '/folder/Informe',
      icon:'documents'
    },{
      title: 'Buques',
      url: '/folder/Buques',
      icon: 'boat'
    },{
      title: 'Edición Usuario',
      url: '/folder/Edicion',
      icon: 'person'
    },

  ];

  public CofradiaPage = [{
      title: "Cuotas",
      url: "/folder/Cuotas",
      icon: "book",
    },{
      title: "Capturas",
      url: "/folder/Capturas",
      icon: "fish",
    },{
      title: "Consulta",
      url: "/folder/Consulta",
      icon: "reader",
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public menuCtrl: MenuController
  ) {
    this.initializeApp();
  }

  cargaMenu() {
    switch (localStorage.getItem("role")) {
      case "Administrador": {
        this.MenuCarga = this.appPages
        break;
      }
      case "Buque": {
        this.MenuCarga = this.BuquePage
        break;
      }
      case "Cooperativa": {
        this.MenuCarga = this.CofradiaPage
        break;
      }
      case "Armador": {
        this.MenuCarga = this.BuquePage
        break;
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.cargaMenu()
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.MenuCarga.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
