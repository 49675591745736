import { Component, OnInit } from '@angular/core';
//import { PagesService } from "./../../api/pages.service";
import { LoginServiceService } from "./login-service/login-service.service";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { MenuController } from '@ionic/angular';
import { AppComponent } from "../../app.component";




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  mostrarPassword = false;
  botonEntrarDeshabilitado = true;
  userRole: string;
  cambioPass: boolean = false;

  constructor(
    private loginService: LoginServiceService,
    private router: Router,
    private app: AppComponent,

    public alertController: AlertController,
    private menu: MenuController
    //private pagesService: PagesService
  ) { this.menu.enable(false, 'custom'); }

  ngOnInit() { }

  async login() {
    let regespPass = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
    );
    if ((<HTMLInputElement>(document.getElementById("ususario"))).value === "") {
      this.notValidNameAlert();
      return;
    }
    if (!regespPass.test((<HTMLInputElement>document.getElementById("clave")).value)) {
      this.notValidPassAlert();
      return;
    }
    this.username = (<HTMLInputElement>(document.getElementById("ususario"))).value;
    this.password = (<HTMLInputElement>document.getElementById("clave")).value;
    let userPass = {
      username: this.username,
      password: this.password,
    };
    this.loginService.login(userPass).subscribe((tokenO) => {
      this.setLoginVars(tokenO);
      this.router.navigate(["/folder/Consulta"]);
    }, (error) => {
      this.notLoggedAlert();
      console.log(error);
    }
    );

  }
  async notValidPassAlert() {
    let alert = await this.alertController.create({
      header: "Contraseña insegura",
      message: "La contraseña es incorrecta",
    });
    await alert.present();
  }
  async notValidNameAlert() {
    let alert = await this.alertController.create({
      header: "Verifique el nombre",
      message: "Introduzca un nombre",
    });
    await alert.present();
  }
  async setLoginVars(tokenO: Object) {
    this.loginService.setAccesToken(tokenO["access_token"]);
    this.loginService.setRefreshToken(tokenO["refresh_token"]);
    this.loginService.setUserRole(tokenO["role"]);
    this.userRole = tokenO["role"];
    this.loginService.setLoggedUser(this.username);
    //this.pagesService.publishRole(tokenO["role"]);
    this.app.cargaMenu();
  }

  toggleType() {
    if (this.mostrarPassword) {
      this.mostrarPassword = false;
    } else {
      this.mostrarPassword = true;
    }
  }

  habilitarBotonEntrar() {
    if (this.password.length >= 3) {
      this.botonEntrarDeshabilitado = false;
    }
  }
  async notLoggedAlert() {
    let alert = await this.alertController.create({
      header: "Usuario / contraseña incorrectos",
      message: "Revise sus credenciales",
    });
    await alert.present();
  }
  changePass() {
    this.cambioPass = true;
  }

  async correoEnviado() {
    let alert = await this.alertController.create({
      header: "Correo enviado",
      message: "Revise sus credenciales",
    });
    await alert.present();
  }
  enviarCorreo() {
    if ((<HTMLInputElement>document.getElementById("correo")).value != "") {
      this.loginService.cambiarContraseña((<HTMLInputElement>document.getElementById("correo")).value).subscribe(data => {
        if (data = "{'success':'Existe'}") {
          console.log("conseguido")
        }
      });
    }
  }
  comprobacion() {
    this.router.navigate(["resetPassword"]);
  }
}
