import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { AlertController } from "@ionic/angular";
import { catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router"
@Injectable({
  providedIn: 'root'
})

export class PrincipalServiceService {

  constructor(
    private router: Router,
    private http: HttpClient,
    public alertController: AlertController
  ) { }

  headers = new HttpHeaders().set("Content-Type", "application/json").append('Authorization', 'Bearer ' + localStorage.getItem("token"));
  //server = "http://localhost:5003";
  server ="https://api.cooperativasantaeugeniaribeiraopp83.es"

  //Component usuario
  getUsers() {
    let usersUrl = this.server + "/get-all-users";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(usersUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  deleteUser(userName: Object) {
    let usersUrl = this.server + "/delete-user";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http
      .post(usersUrl, userName, { headers: x })
      .subscribe(
        (data) => { },
        (error) => {
          this.notValidDeleteNameAlert(userName);
        }
      );
  }

  async notValidDeleteNameAlert(newUserName: Object) {
    let alert = await this.alertController.create({
      header: "Nombre no valido",
      message: "El usuario " + newUserName["usuario"] + " no existe",
    });
    await alert.present();
  }

  //Componente cuotas
  getAvailableQuota(boat: string, date: string) {
    let quotaUrl = this.server + "/quota/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(quotaUrl, { headers: x });
  }

  getAnnualQuota(boat: string, year: string) {
    let quotaUrl = this.server + "/get-quota/" + boat + "/" + year;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(quotaUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  async createQuota(quotaObject: Object) {
    let quotaUrl = this.server + "/create-quota";
    console.log(quotaUrl)
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http
      .post(quotaUrl, quotaObject, { headers: x }).subscribe((data) => {
        return data
      });

  }

  removeQuota(quotaObject: Object) {
    let quotaUrl = this.server + "/edit-quota/delete";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.post(quotaUrl, quotaObject, { headers: x }).subscribe((data) => {
      console.log(data);
    });
  }
  //Creacion de usuario
  createUser(newUser: Object) {
    let usersUrl = this.server + "/create-user";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.post(usersUrl, newUser, { headers: x }).subscribe((data) => {

    },
      (error) => {
        this.notValidNameAlert(newUser["usuario"]);
      }
    );
  }

  async notValidNameAlert(newUserName: string) {
    let alert = await this.alertController.create({
      header: "Nombre no valido",
      message: "El usuario " + newUserName + " ya existe",
    });
    await alert.present();
  }

  //creacion Buque
  createBoat(newBoat: Object) {
    let boatUrl = this.server + "/create-ship";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.post(boatUrl, newBoat, { headers: x });
  }


  getBoats() {
    let boatUrl = this.server + "/get-all-ships";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(boatUrl, { headers: x })/*.pipe(
      catchError(err => {
          if(err.error["msg"]=="Token has expired"){
            this.refresh().subscribe(data => {
              localStorage.setItem("token",data["access_token"])
            });
          }

          let headers = new HttpHeaders().set("Content-Type", "application/json").append('Authorization','Bearer ' + localStorage.getItem("token"));
          return this.http.get(boatUrl, { headers: headers }).pipe(tap((response: HttpResponse<object>) => {
                return response;
          })
          )
          
      })
  )*/.pipe(tap((response: HttpResponse<object>) => {
      return response;
    })
    )

  }
  refresh() {
    let refresh = this.server + "/refresh";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("refreshToken")).append("skip", "skip");

    return this.http.post(refresh, "", { headers: x }).pipe(
      catchError(err => {
        if (err.error["msg"] == "Token has expired") {
          this.router.navigate(['/']);
        }
        return "ok"
      })
    ).pipe(tap((response: HttpResponse<object>) => {
      return response;
    })
    )
  }

  getBoatUser() {
    let boatUrl = this.server + "/getShipByUser/" + localStorage.getItem('user');
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(boatUrl, { headers: x }).pipe(
      tap((response: HttpResponse<object>) => {
        return response;
      })
    );
  }


  getBoatArmador() {
    let boatUrl = this.server + "/getShipsByArmador/" + localStorage.getItem('user');
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(boatUrl, { headers: x }).pipe(
      tap((response: HttpResponse<object>) => {
        return response;
      })
    );
  }

  async notValidRegistrationAlert(registration: string) {
    let alert = await this.alertController.create({
      header: "Revise matricula",
      message: "Matricula: " + registration + " ya tiene un barco asignado.",
    });
    await alert.present();
  }

  //consulta
  getReport(boat: string, year: string) {
    let reportUrl = this.server + "/report/" + boat + "/" + year;
    console.log(reportUrl)
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(reportUrl, { headers: x });
  }

  getSpeciesByBoat(boat: string, date: string) {
    let speciesUrl = this.server + "/get-species-by-boat/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(speciesUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }
  getMonthlySpecies(boat: string, date: string, specie: string) {
    let specieObj = { specie: specie };
    let speciesUrl = this.server + "/get-species-monthly/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http
      .post(speciesUrl, specieObj, { headers: x })
      .pipe(
        tap((response: HttpResponse<Object>) => {
          return response;
        })
      );
  }
  //Capturas
  createSwap(newSwap: Object) {
    let swapUrl = this.server + "/create-swap";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.post(swapUrl, newSwap, { headers: x })
  }

  getSwaps(boat: string, date: string) {
    let swapUrl = this.server + "/get-swaps/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(swapUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  getSwapsByDate(boat: string, date: string) {
    let swapUrl = this.server + "/get-swaps-by-date/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(swapUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  getCatchesByBoat(boat: string, date: string) {
    let catchUrl = this.server + "/get-catches/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(catchUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  getCatchesByDay(boat: string, date: string) {
    let catchUrl = this.server + "/get-catch/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(catchUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }
  createCatch(catchObject: Object) {
    let catchUrl = this.server + "/create-catch";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http
      .post(catchUrl, catchObject, { headers: x })

  }
  removeCatch(boat: string, date: string) {
    let catchUrl = this.server + "/remove-catch/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(catchUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }
  getDaysCaught(boat: string, date: string) {
    let catchUrl = this.server + "/days-caught/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(catchUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  getMonthlyCatches(boat: string, date: string) {
    let catchUrl = this.server + "/catches-monthly/" + boat + "/" + date;
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(catchUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  //Barcos
  deleteBoat(boatName: Object) {
    let usersUrl = this.server + "/delete-ship";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http
      .post(usersUrl, boatName, { headers: x })
      .subscribe(
        (data) => { },
        (error) => {
          this.notValidDeleteNameAlert(boatName);
        }
      );
  }

  async notValidDeleteBoatAlert(newBoatName: Object) {

    let alert = await this.alertController.create({
      header: "Nombre no valido",
      message: "El buque de matricula" + newBoatName["matricula"] + " no existe",
    });
    await alert.present();
  }

  selectBoat(userName: string) {
    let shipUrl = this.server + "/select-ship";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(shipUrl, { headers: x }).pipe(
      tap((response: HttpResponse<Object>) => {
        return response;
      })
    );
  }

  getCuotasMensual(boat: string, date: string) {
    let catchUrl = this.server + "/total-mes/" + boat + "/" + date + "/";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(catchUrl, { headers: x })
  }

  getNombreBarco(nombre: string) {
    let speciesUrl = this.server + "/nombre-buque/" + nombre + "/";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(speciesUrl, { responseType: 'text', headers: x })
  }

  getBarcosByArmador(codigo: string) {
    let speciesUrl = this.server + "/armador-barco/" + codigo + "/";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(speciesUrl, { headers: x })
  }

  getCodigoArmador(nombre: string) {
    let speciesUrl = this.server + "/codigo-armador/" + nombre + "/";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.get(speciesUrl, { responseType: 'text', headers: x })
  }

  cambiarPass(user: object) {
    let speciesUrl = this.server + "/cambiarPass/";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.post(speciesUrl, user, { headers: this.headers })
  }
  editarBarco(barco: object) {
    let speciesUrl = this.server + "/edit-ship";
    let x = new HttpHeaders();
    x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
    return this.http.post(speciesUrl, barco, { headers: this.headers })
  }
  getGruposEmpresarial() {
    let speciesUrl = this.server + "/gruposEmpresarial";
    let x = new HttpHeaders();
    if (localStorage.getItem('role') == 'Armador') {
      let params = new HttpParams().set("user", localStorage.getItem('user'))
      x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
      return this.http.get(speciesUrl, { responseType: 'text', headers: x, params: params })
    } else {
      x = x.set('Content-Type', 'application/json;').append('Authorization', 'Bearer ' + localStorage.getItem("token"));
      return this.http.get(speciesUrl, { responseType: 'text', headers: x })
    }
  }

  updateGrupo(grupo) {
    let speciesUrl = this.server + "/updateGrupo";
    return this.http.post(speciesUrl, grupo, { headers: this.headers })
  }

  deleteGrupo(grupo) {
    let speciesUrl = this.server + "/deleteGrupo";
    return this.http.post(speciesUrl, grupo, { headers: this.headers })
  }

  getCuotaAgrupada(grupo, año) {
    let speciesUrl = this.server + "/cuotaAgrupada";
    let params = new HttpParams().set("grupo", grupo).set("year", año)
    return this.http.get(speciesUrl, { responseType: 'text', headers: this.headers, params: params })
  }

  getConsumoAgrupado(grupo, año) {
    let speciesUrl = this.server + "/consumoAgrupado";
    let params = new HttpParams().set("grupo", grupo).set("year", año)
    return this.http.get(speciesUrl, { responseType: 'text', headers: this.headers, params: params })
  }

  getIntercambioAgrupado(grupo, año) {
    let speciesUrl = this.server + "/intercambioAgrupado";
    let params = new HttpParams().set("grupo", grupo).set("year", año)
    return this.http.get(speciesUrl, { responseType: 'text', headers: this.headers, params: params })
  }

  getResumenAgrupado(grupo, año) {
    let speciesUrl = this.server + "/resumenAgrupado";
    let params = new HttpParams().set("grupo", grupo).set("year", año)
    return this.http.get(speciesUrl, { responseType: 'text', headers: this.headers, params: params })
  }

  getDescartesAgrupado(grupo, año) {
    let speciesUrl = this.server + "/descartesAgrupado";
    let params = new HttpParams().set("grupo", grupo).set("year", año)
    return this.http.get(speciesUrl, { responseType: 'text', headers: this.headers, params: params })
  }


  getDescartes(barco, mes) {
    let speciesUrl = this.server + "/getDescartes";
    let params = new HttpParams().set("buque", barco).set("month", mes)
    return this.http.get(speciesUrl, { responseType: 'text', headers: this.headers, params: params })
  }

  updateDescartes(aux) {
    let speciesUrl = this.server + "/updateDescarte";
    return this.http.post(speciesUrl, aux, { headers: this.headers })
  }

  deleteDescarte(data) {
    let speciesUrl = this.server + "/deleteDescarte";
    return this.http.post(speciesUrl, data, { headers: this.headers })
  }
  deleteCaptura(data) {
    let speciesUrl = this.server + "/deleteCaptura";
    return this.http.post(speciesUrl, data, { headers: this.headers })
  }

  deleteIntercambio(data) {
    let speciesUrl = this.server + "/deleteIntercambio";
    return this.http.post(speciesUrl, data, { headers: this.headers })
  }

  insertDescarte(data) {
    let speciesUrl = this.server + "/insertDescarte";
    return this.http.post(speciesUrl, data, { headers: this.headers })
  }
  getReports(boat, year) {
    let speciesUrl = this.server + "/getReport/" + boat + "/" + year + "/" +boat+year;
    return this.http.post(speciesUrl, '',{ responseType: 'blob', headers: this.headers })
  }

  getPdf(boat,year) {
    let speciesUrl = this.server + "/getPdf/" + boat + "/" + year;
    return this.http.post(speciesUrl,'',{ responseType: 'blob', headers: this.headers })
  }

  
}
