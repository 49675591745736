//import { LocalDataService } from './../services/local-data.service';
//import { ConfigService } from './../services/config.service';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
//import { AuthService } from '../services/auth.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { PrincipalServiceService } from "../folder/service/principal-service.service";
import { Router } from '@angular/router';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
    constructor( private router: Router, private inj: Injector,public auth: PrincipalServiceService) {
    }
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
      const token: string = localStorage.getItem('token');
  
      let request = req;
  
      //console.log(request)
  
      if (token && !request.url.includes("refresh")) {
        request = this.addToken(request, token);
      }
  
      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          if (!request.url.includes("refresh") && err.status === 401) {
              console.log("entra")
            return this.handle401Error(request, next);
          } else if (request.url.includes("refresh")) {
                this.router.navigate(["/login"])
          }
          return throwError( err );
        })
      );
    }
  
    private handle401Error(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

    
        return this.auth.refresh().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token['access_token']);
            localStorage.setItem('token', token['access_token']);
            return next.handle(this.addToken(request, token['access_token']));
          }));
    
      } else {
        return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
            return next.handle(this.addToken(request, jwt));
          }));
      }
    }
  
    private addToken(request: HttpRequest<any>, token: string) {
    // Para evitar cambiar todas las llamadas para que los consignatarios puedan tener mas de un puerto se añade el puerto a filtrar a traves del interceptor
    // y la api filtrara a traves de el
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
        }
      });
    }
}
