import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from "@angular/router";
import { RouterModule, Routes } from '@angular/router';
import { LoginServiceService } from "./login/login-service/login-service.service"


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  headers = new HttpHeaders().set("Content-Type", "application/json");
  
  
  //server = "http://localhost:5003";
  
  server ="https://api.cooperativasantaeugeniaribeiraopp83.es"

  constructor(private router: Router,
    private loginService: LoginServiceService,
    private http: HttpClient,) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') == null) {
      this.router.navigate(["login"])
      return false;
    } else {
      return true;
    }
  }
  
  cambiarPass(correo: object) {
    let speciesUrl = this.server + "/restaurarPass";
    return this.http.post(speciesUrl, correo, { headers: this.headers })
  }
}
