import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Auth/login/login.component'
import { ResetPassComponent } from './Auth/reset-pass/reset-pass.component'
import { AuthGuardService } from './Auth/auth-guard.service'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'folder',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuardService]
  },
 
  {
    path: 'resetPassword',
    component: ResetPassComponent
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],

  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AppRoutingModule { }
