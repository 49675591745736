import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { IonicStorageModule } from '@ionic/storage';
import { Storage } from '@ionic/storage';
import { tap } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  headers = new HttpHeaders().set("Content-Type", "application/json").append("skip","skip");
  //server = "http://localhost:5003";
  server ="https://api.cooperativasantaeugeniaribeiraopp83.es"

  constructor(private http: HttpClient, public store: Storage) { }

  login(userPass: Object) {
    let longinUrl = this.server + "/login";
    return this.http.post(longinUrl, userPass, { headers: this.headers });
  }
  cambiarContraseña(correo: string) {
    let speciesUrl = this.server + "/cambiarPass/" + correo;
    return this.http.get(speciesUrl, { responseType: 'text' })
  }


  async setValor(token: string) {
    return await this.store.set('token', token);
  }


  setAccesToken(token: string): void {
    localStorage.setItem('token', token);

    //this.token=token;
  }
  setRefreshToken(refreshToken: string): void {
    localStorage.setItem('refreshToken', refreshToken)

  }
  setUserRole(role: string): void {
    localStorage.setItem('role', role)
  }
  setLoggedUser(user: string): void {
    localStorage.setItem('user', user)
  }
}
